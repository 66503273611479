import {
  Box,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  EPixel,
  SvgIcon,
  useMobileQuery,
  useTabletQuery,
} from '@hkexpressairwayslimited/ui';
import { Stack, styled } from '@mui/material';
import {
  Placeholder,
  getChildPlaceholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

type SliderCardProps = ComponentProps;

const StyledSlider = styled(Slider)(() => ({
  overflow: 'hidden',
  '.slick-track': {
    display: 'flex',
  },
  '.slick-list': {
    margin: '0 -11px',
  },
  '.slick-slide': {
    height: 'initial',
  },
  '.slick-slide > div': {
    margin: '0 12px',
    height: '100%',
  },
  '.slick-dots': {
    position: 'initial',
    marginTop: '30px',
    display: 'flex',
    '& > li': {
      width: 'initial',
      height: 'initial',
    },
    bottom: EPixel.px16,
  },
}));

export const constructSliderHtml = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: (elementArr: JSX.Element[]) => void
) => {
  const innerHtml = ref?.current?.innerHTML;
  const div = document.createElement('div');
  div.innerHTML = innerHtml || '';
  if (!innerHtml) {
    return;
  }
  if (div.children?.length > 0) {
    const divArr = Array.from(div.children).map((child, index) => {
      const childDiv = document.createElement('div');
      childDiv.appendChild(child);
      return (
        <Box
          sx={{ height: '100%', '& > div': { height: '100%' } }}
          key={index}
          dangerouslySetInnerHTML={{ __html: childDiv?.innerHTML || '' }}
        ></Box>
      );
    });
    callback && callback(divArr);
  }
};

const SliderCard = (props: SliderCardProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const sliderRef = useRef<Slider>(null);
  const placeHolderRef = useRef<HTMLElement | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [placeHolderArr, setPlaceHolderArr] = useState<JSX.Element[]>([]);
  const isMobile = useMobileQuery();
  const isTablet = useTabletQuery();
  const slidesToShow = isMobile ? 1 : isTablet ? 2 : 3;
  const placeholders = getChildPlaceholder(props.rendering, 'slider-card-placeholders');
  const cardNumber = placeholders.length;
  const page = Math.ceil(cardNumber / slidesToShow);
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    afterChange: (currentSlide: number) => {
      setCurrentSlide(currentSlide);
    },
    appendDots: (dots: ReactNode) => {
      const curPage = Math.ceil(currentSlide / 3);
      return (
        <Stack direction="row" alignItems="center" justifyContent="center">
          {
            <Button custom>
              <SvgIcon
                sx={{
                  color: '#8A8F9D',
                  opacity: curPage === 0 ? '0.3' : 1,
                }}
                onClick={
                  curPage === 0
                    ? () => {}
                    : () => {
                        sliderRef.current?.slickPrev();
                      }
                }
              >
                <ChevronLeftIcon />
              </SvgIcon>
            </Button>
          }
          {dots}
          {
            <Button custom>
              <SvgIcon
                sx={{ color: '#8A8F9D', opacity: curPage === page - 1 ? '0.3' : 1 }}
                onClick={
                  curPage === page - 1
                    ? () => {}
                    : () => {
                        sliderRef.current?.slickNext();
                      }
                }
              >
                <ChevronRightIcon />
              </SvgIcon>
            </Button>
          }
        </Stack>
      );
    },
    customPaging: function (i: number) {
      const curPage = Math.ceil(currentSlide / 3);
      return (
        <Box
          sx={{
            fontWeight: curPage === i ? 'bolder' : 'initial',
            color: curPage === i ? '#702B91' : '#8A8F9D',
            margin: '0 16px',
          }}
        >
          {i + 1}
        </Box>
      );
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    constructSliderHtml(placeHolderRef, (elementArr) => {
      setPlaceHolderArr(elementArr);
    });
  }, [placeholders]);
  return (
    <>
      {isPageEditing ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            '& > div': {
              width: '300px !important',
              margin: '0 10px',
            },
          }}
        >
          <Placeholder name={`slider-card-placeholders`} rendering={props.rendering} />
        </Box>
      ) : cardNumber > slidesToShow && !isMobile ? (
        <StyledSlider className={`${props.params?.styles}`} ref={sliderRef} {...settings}>
          {placeHolderArr}
          {/* {placeholders.map((item: ComponentRendering, index: number) => {
            return <Placeholder key={index} name="slider-card-placeholders" rendering={item} />;
          })} */}
        </StyledSlider>
      ) : (
        <Box sx={{ overflow: 'hidden' }} className={`${props.params?.styles}`}>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{
              width: 'calc(100% + 24px)',
              marginLeft: '-12px',
              marginBottom: '-8px',
              '& > div': {
                width: `calc(${100 / slidesToShow}% - 24px)`,
                margin: '8px 12px',
              },
            }}
          >
            <Placeholder name={`slider-card-placeholders`} rendering={props.rendering} />
          </Stack>
        </Box>
      )}
      <Box
        sx={{ position: 'absolute', zIndex: -999, opacity: 0, height: 0, overflow: 'hidden' }}
        ref={placeHolderRef}
      >
        <Placeholder name={`slider-card-placeholders`} rendering={props.rendering} />
      </Box>
    </>
  );
};

export default withDatasourceCheck()<SliderCardProps>(SliderCard);
