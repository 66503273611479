import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'react';

import classes from './JssRichText.module.scss';

export const JssRichText = ({ field, ...others }: ComponentProps<typeof RichText>) => {
  // const sanitizedField = useMemo(
  //   () =>
  //     field
  //       ? {
  //           ...field,
  //           value: renderToString(parseHtmlToReact(field.value || '')),
  //         }
  //       : field,
  //   [field]
  // );

  return <RichText className={classes.jssRichText} field={field} {...others} />;
};
