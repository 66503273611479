import { EPixel, useMobileQuery } from '@hkexpressairwayslimited/ui';
import { Stack, styled } from '@mui/material';
import {
  Field,
  ImageField,
  Placeholder,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

const StyledStack = styled(Stack)(() => ({
  '& img': {
    objectFit: 'cover',
    maxWidth: '100%',
    borderRadius: EPixel.px16,
  },
}));

type IconTextProps = ComponentProps & {
  fields: {
    image: ImageField;
    numberText: Field<string>;
  };
};

const IconText = (props: IconTextProps): JSX.Element => {
  const isMobile = useMobileQuery();
  const isVertical = props.params?.isVertical;
  const hasNumber = props.params?.hasNumber;
  const width = props.params?.imageWidth ? parseFloat(props.params.imageWidth) : undefined;
  const isLittleIcon = props.params?.isLittleIcon;
  return (
    <StyledStack
      direction={(isMobile || isVertical) && !isLittleIcon ? 'column' : 'row'}
      className={`${props.params?.styles}`}
    >
      <Stack direction="row" alignItems="center" justifyContent="center">
        <JssImage field={props.fields?.image} width={isVertical ? width : 295 || width} />
      </Stack>
      <Stack
        direction="row"
        sx={{
          ml:
            (isMobile || isVertical) && !isLittleIcon ? 0 : isLittleIcon ? EPixel.px4 : EPixel.px24,
          mt: (isMobile || isVertical) && !isLittleIcon ? EPixel.px24 : 0,
          flex: 1,
        }}
      >
        {hasNumber && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: EPixel.px24,
              height: EPixel.px24,
              backgroundColor: '#E2D5E9',
              color: '#702B91',
              fontWeight: 'bolder',
              borderRadius: '50%',
              mr: EPixel.px20,
              mt: EPixel.px4,
            }}
          >
            <Text field={props.fields?.numberText} />
          </Stack>
        )}
        <Stack
          justifyContent={isVertical ? 'flex-start' : 'center'}
          sx={{ flex: 1, textAlign: 'left' }}
        >
          <Placeholder name="icon-text-placeholders" rendering={props.rendering} />
        </Stack>
      </Stack>
    </StyledStack>
  );
};

export default withDatasourceCheck()<IconTextProps>(IconText);
