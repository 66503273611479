import {
  AccountIcon,
  CURRENCY_OPTIONS,
  CathayIcon,
  HeaderPropsType,
  Header as HeaderUi,
  IdTokenPayload,
  Link,
  OnlineCheckInIcon,
  SelectOption,
  TokenProfile,
  clearAuthTokens,
  decodeTokenPayLoad,
  decodeTokenProfile,
  getCookieDomain,
  getIdToken,
} from '@hkexpressairwayslimited/ui';
import { ImageField, Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Cookies from 'js-cookie';
import { ComponentProps } from 'lib/component-props';
import { envConfig } from 'lib/services/env';
import { useTransContent } from 'lib/transContent';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { JssImage } from 'lib/components';
import nextConfig from 'next.config';

type HeaderProps = ComponentProps & {
  fields: {
    logo: ImageField;
    mobileMenuLogo: ImageField;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const Header = ({ fields, rendering }: HeaderProps): JSX.Element => {
  const { AlertMessage } = HeaderUi.AlertMessageBar;
  const { t } = useTransContent();
  const router = useRouter();
  const [currency, setCurrency] = useState(
    (typeof window !== 'undefined' && Cookies.get('currency')) || envConfig.defaultCurrency
  );
  const [tokenProfile, setTokenProfile] = useState<TokenProfile>();

  const onLogout = useCallback(() => {
    clearAuthTokens();
    setTokenProfile(undefined);
  }, []);

  const changeLanguage = useCallback(
    (lang: string) => {
      const { pathname, asPath, query } = router;

      Cookies.set('i18nextLng', lang, { domain: getCookieDomain(), path: '/', expires: 365 });
      router.push({ pathname, query }, asPath, { locale: lang });
    },
    [router]
  );

  const changeCurrency = useCallback((currency: string) => {
    setCurrency(currency);
  }, []);

  useEffect(() => {
    Cookies.set('currency', currency, { domain: getCookieDomain(), path: '/', expires: 365 });
  }, [currency]);

  const localeOptions = useMemo(
    () =>
      nextConfig().i18n?.locales.map((locale) => ({
        value: locale,
        label: t(`web.general.locale.options.${locale}`),
        onChange: (option: SelectOption) => {
          changeLanguage(option.value);
        },
      })),
    [changeLanguage, t]
  );

  const currencyOptions = useMemo(
    () =>
      CURRENCY_OPTIONS.filter((option) => option.value !== currency).map((rate) => ({
        value: rate.value,
        label: t(`web.general.currency.options.${rate.value}`),
        onChange: (option: SelectOption) => {
          changeCurrency(option.value);
        },
      })),
    [t]
  );

  const alertMessages = useMemo(() => {
    const messagesList = [];
    let i = 1;

    do {
      const titleKey = `web.header.alertMessages.${i}.title`;
      const contentKey = `web.header.alertMessages.${i}.content`;
      const title = t(titleKey);
      const content = t(contentKey);

      if (title === titleKey && content === contentKey) {
        break;
      }
      messagesList.push({
        key: i,
        title,
        content,
      });
      i++;
      // eslint-disable-next-line no-constant-condition
    } while (true);

    return messagesList.map(({ key, ...messageProps }) => (
      <AlertMessage key={key} {...messageProps} />
    ));
  }, [t]);

  useEffect(() => {
    const idToken = getIdToken();

    if (!idToken) return;
    const idTokenPayload = decodeTokenPayLoad<IdTokenPayload>(idToken);
    if (!idTokenPayload) return;
    const tokenProfile = decodeTokenProfile(idTokenPayload);

    setTokenProfile(tokenProfile);
  }, []);

  const title = useMemo(
    () => ((tokenProfile && t(`web.general.title.options.${tokenProfile?.title}`)) as string) || '',
    [t, tokenProfile]
  );

  const otherProps: Partial<HeaderPropsType> = useMemo(
    () => ({
      ...{
        loginLabel: t('web.header.booking.loginLabel'),
        logoutLabel: t('web.header.booking.logoutLabel'),
        signInMenu: [
          {
            label: t('web.header.booking.signInMenu.SignUp'),
            itemProps: {
              onClick: () => {
                router.push(`${envConfig.bookingHost}/member/signup`);
              },
            },
          },
          {
            label: t('web.header.booking.signInMenu.Login'),
            itemProps: {
              onClick: () => {
                const query = new URLSearchParams({ redirect: window.location.href });

                router.push(`${envConfig.bookingHost}/member/signin?${query}`);
              },
            },
          },
        ],
        ...(tokenProfile
          ? {
              profile: {
                title,
                fullName: `${tokenProfile.given_name} ${tokenProfile.family_name}`,
                lastName: tokenProfile.family_name,
              },
              membershipMenu: [
                {
                  label: t('web.header.booking.membershipMenu.MyBookings'),
                  itemProps: {
                    leadingIcon: <OnlineCheckInIcon />,
                    onClick: () =>
                      router.push(`${envConfig.bookingHost}/manage-my-booking/my-trips`),
                  },
                },
                {
                  label: t('web.header.booking.membershipMenu.ProfileUpdate'),
                  itemProps: {
                    leadingIcon: <AccountIcon />,
                    onClick: () => router.push(`${envConfig.bookingHost}/member/profile`),
                  },
                },
                {
                  label: t('web.header.booking.membershipMenu.MyCathayAccount'),
                  itemProps: {
                    leadingIcon: <CathayIcon />,
                    onClick: () => router.push(`${envConfig.bookingHost}/member/link-cx`),
                  },
                },
              ],
            }
          : {}),
        ...{
          currencyOptions,
          currencyValue: currency,
        },
      },
    }),
    [t, tokenProfile, title, currencyOptions, currency, router.pathname]
  );

  const onMobileAccountClick = useCallback(() => {
    const query = new URLSearchParams({ redirect: window.location.href });

    router.push(`${envConfig.bookingHost}/member/signin?${query}`);
  }, []);

  return (
    <>
      <HeaderUi
        alertMessageBarProps={{
          messages: alertMessages,
        }}
        logo={{
          header: (
            <Link href={`/${router.locale}/`}>
              <JssImage
                field={fields?.logo}
                width={0}
                height={0}
                style={{ width: 'auto', height: '100%', minHeight: 'unset' }}
              />
            </Link>
          ),
          mobileMenu: (
            <Link href={`/${router.locale}/`}>
              <JssImage
                field={fields?.mobileMenuLogo}
                width={0}
                height={0}
                style={{ width: 'auto', height: '100%', minHeight: 'unset' }}
              />
            </Link>
          ),
        }}
        onLogout={onLogout}
        navItems={<Placeholder name="nav-items" rendering={rendering} />}
        localeOptions={localeOptions}
        localeValue={router.locale}
        onMobileAccountClick={onMobileAccountClick}
        {...otherProps}
      />
    </>
  );
};

export default withDatasourceCheck()<HeaderProps>(Header);
